import { cifGb, cifLt, cifRu, cilFlagAlt } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useUser } from "@clerk/clerk-react";

export const LocaleSelect = () => {
  const { i18n, t } = useTranslation();
  const { user } = useUser();
  const defaultLanguage = user?.unsafeMetadata?.countryCode || "en";

  const handleLocaleChange = (locale: "en" | "lt") => {
    i18n.changeLanguage(locale);
  };

  let currentLocaleDisplay = null;

  switch (i18n.language) {
    case "en":
      currentLocaleDisplay = (
        <CIcon icon={cifGb} size="xl" className="mb-[-3px]" />
      );
      break;
    case "lt":
      currentLocaleDisplay = (
        <CIcon icon={cifLt} size="xl" className="mb-[-3px]" />
      );
      break;

    default:
      // * FIXME - RU Lang
      // currentLocaleDisplay = (
      //   <CIcon icon={cifRu} size="xl" className="mb-[-3px]" />
      // );
      currentLocaleDisplay = (
        <CIcon icon={cifGb} size="xl" className="mb-[-3px]" />
      );
  }

  return (
    <CDropdown>
      <CDropdownToggle color="primary" className="p-1 nav-link">
        {currentLocaleDisplay}
      </CDropdownToggle>
      <CDropdownMenu>
        <CDropdownItem
          className={`cursor-pointer flex items-center space-x-2 ${i18n.language === "en" ? "bg-gray-200" : ""}`}
          active={i18n.language === "en"}
          onClick={() => handleLocaleChange("en")}
        >
          <CIcon icon={cifGb} size="xl" className="mb-[-3px]" /> English
          {i18n.language === "en" && <span>•</span>}
          {defaultLanguage === "en" && <span className="text-gray-900 italic ml-2">({t("UI.DefaultFemale")})</span>}
        </CDropdownItem>
        <CDropdownItem className={`cursor-pointer flex items-center space-x-2 ${i18n.language === "lt" ? "bg-gray-200" : ""}`}
          active={i18n.language === "lt"}
          onClick={() => handleLocaleChange("lt")}
        >
          <CIcon icon={cifLt} size="xl" className="mb-[-3px]" /> Lietuvių
          {i18n.language === "lt" && <span>•</span>}
          {defaultLanguage === "lt" && <span className="text-gray-900 italic ml-2">({t("UI.DefaultFemale")})</span>}
        </CDropdownItem>
        
        {/* FIXME - RU Lang
         <CDropdownItem
          className="cursor-pointer"
          active={i18n.language === "ru"}
          onClick={() => handleLocaleChange("ru")}
        >
          <CIcon icon={cifRu} size="xl" className="mb-[-3px]" /> Русский
        </CDropdownItem> */}
      </CDropdownMenu>
    </CDropdown>
  );
};
