import React from "react";

const Colors = React.lazy(() => import("./views/theme/colors/colors"));
const Typography = React.lazy(
  () => import("./views/theme/typography/typography")
);

// Base
const Accordion = React.lazy(() => import("./views/base/accordion/accordion"));
const Breadcrumbs = React.lazy(
  () => import("./views/base/breadcrumbs/breadcrumbs")
);
const Cards = React.lazy(() => import("./views/base/cards/cards"));
const Collapses = React.lazy(() => import("./views/base/collapses/collapses"));
const ListGroups = React.lazy(
  () => import("./views/base/list-groups/list-groups")
);
const Navs = React.lazy(() => import("./views/base/navs/navs"));
const Paginations = React.lazy(
  () => import("./views/base/paginations/paginations")
);
const Placeholders = React.lazy(
  () => import("./views/base/placeholders/placeholders")
);
const Popovers = React.lazy(() => import("./views/base/popovers/popovers"));
const Progress = React.lazy(() => import("./views/base/progress/progress"));
const Spinners = React.lazy(() => import("./views/base/spinners/spinners"));
const Tables = React.lazy(() => import("./views/base/tables/tables"));
const Tooltips = React.lazy(() => import("./views/base/tooltips/tooltips"));

// Buttons
const Buttons = React.lazy(() => import("./views/buttons/buttons/buttons"));
const ButtonGroups = React.lazy(
  () => import("./views/buttons/button-groups/button-groups")
);
const Dropdowns = React.lazy(
  () => import("./views/buttons/dropdowns/dropdowns")
);

//Forms
const ChecksRadios = React.lazy(
  () => import("./views/forms/checks-radios/checks-radios")
);
const FloatingLabels = React.lazy(
  () => import("./views/forms/floating-labels/floating-labels")
);
const FormControl = React.lazy(
  () => import("./views/forms/form-control/form-control")
);
const InputGroup = React.lazy(
  () => import("./views/forms/input-group/input-group")
);
const Layout = React.lazy(() => import("./views/forms/layout/layout"));
const Range = React.lazy(() => import("./views/forms/range/range"));
const Select = React.lazy(() => import("./views/forms/select/select"));
const Validation = React.lazy(
  () => import("./views/forms/validation/validation")
);

const Charts = React.lazy(() => import("./views/charts/charts"));

// Icons
const CoreUIIcons = React.lazy(
  () => import("./views/icons/coreui-icons/core-ui-icons")
);
const Flags = React.lazy(() => import("./views/icons/flags/flags"));
const Brands = React.lazy(() => import("./views/icons/brands/brands"));

// Notifications
const Alerts = React.lazy(() => import("./views/notifications/alerts/alerts"));
const Badges = React.lazy(() => import("./views/notifications/badges/badges"));
const Modals = React.lazy(() => import("./views/notifications/modals/modals"));
const Toasts = React.lazy(() => import("./views/notifications/toasts/toasts"));

const Widgets = React.lazy(() => import("./views/widgets/widgets"));

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/register", exact: true, name: "Register" },
  { path: "/about", exact: true, name: "About" },
  { path: "/listing", name: "Listings" },
  { path: "/listing/create", name: "New Listing" },
  { path: "/listing/edit", name: "" },
  {
    path: "/listing/edit/:listingId",
    name: "Edit Listing",
    state: "listing-edit",
  },
  {
    path: "/listing/:listingId:/offer/:offerId/view",
    name: "Offer View",
    state: "offer-view",
  },
  {
    path: "/discover",
    name: "Discover",
  },
  {
    path: "/discover/:listingId",
    name: "Preview",
    state: "discover-preview",
  },
    {
    path: "/discover/:listingId",
    name: "Preview",
    state: "discover-preview",
  },
  {
    path: "/my-drafts",
    name: "My Drafts",
  },
  {
    path: "/enrollments",
    name: "My Enrollments",
  },
  {
    path: "/my-favorites",
    name: "Starred Favorites",
  },
  { path: "/theme", name: "Theme", element: Colors, exact: true },
  { path: "/theme/colors", name: "Colors", element: Colors },
  { path: "/theme/typography", name: "Typography", element: Typography },
  { path: "/base", name: "Base", element: Cards, exact: true },
  { path: "/base/accordion", name: "Accordion", element: Accordion },
  { path: "/base/breadcrumbs", name: "Breadcrumbs", element: Breadcrumbs },
  { path: "/base/cards", name: "Cards", element: Cards },
  { path: "/base/collapses", name: "Collapse", element: Collapses },
  { path: "/base/list-groups", name: "List Groups", element: ListGroups },
  { path: "/base/navs", name: "Navs", element: Navs },
  { path: "/base/paginations", name: "Paginations", element: Paginations },
  { path: "/base/placeholders", name: "Placeholders", element: Placeholders },
  { path: "/base/popovers", name: "Popovers", element: Popovers },
  { path: "/base/progress", name: "Progress", element: Progress },
  { path: "/base/spinners", name: "Spinners", element: Spinners },
  { path: "/base/tables", name: "Tables", element: Tables },
  { path: "/base/tooltips", name: "Tooltips", element: Tooltips },
  { path: "/buttons", name: "Buttons", element: Buttons, exact: true },
  { path: "/buttons/buttons", name: "Buttons", element: Buttons },
  { path: "/buttons/dropdowns", name: "Dropdowns", element: Dropdowns },
  {
    path: "/buttons/button-groups",
    name: "Button Groups",
    element: ButtonGroups,
  },
  { path: "/charts", name: "Charts", element: Charts },
  { path: "/forms", name: "Forms", element: FormControl, exact: true },
  { path: "/forms/form-control", name: "Form Control", element: FormControl },
  { path: "/forms/select", name: "Select", element: Select },
  {
    path: "/forms/checks-radios",
    name: "Checks & Radios",
    element: ChecksRadios,
  },
  { path: "/forms/range", name: "Range", element: Range },
  { path: "/forms/input-group", name: "Input Group", element: InputGroup },
  {
    path: "/forms/floating-labels",
    name: "Floating Labels",
    element: FloatingLabels,
  },
  { path: "/forms/layout", name: "Layout", element: Layout },
  { path: "/forms/validation", name: "Validation", element: Validation },
  { path: "/icons", exact: true, name: "Icons", element: CoreUIIcons },
  { path: "/icons/coreui-icons", name: "CoreUI Icons", element: CoreUIIcons },
  { path: "/icons/flags", name: "Flags", element: Flags },
  { path: "/icons/brands", name: "Brands", element: Brands },
  {
    path: "/notifications",
    name: "Notifications",
    element: Alerts,
    exact: true,
  },
  { path: "/notifications/alerts", name: "Alerts", element: Alerts },
  { path: "/notifications/badges", name: "Badges", element: Badges },
  { path: "/notifications/modals", name: "Modals", element: Modals },
  { path: "/notifications/toasts", name: "Toasts", element: Toasts },
  { path: "/widgets", name: "Widgets", element: Widgets },
];

export default routes;
