import { useAppDispatch } from "@/store/hooks";
import { addToastMain } from "@/store/toast/slice";
import { useSignIn } from "@clerk/clerk-react";
import {
  CButton,
  CForm,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSpinner,
} from "@coreui/react";
import { FormEvent, useState } from "react";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { useNavigate } from "react-router-dom";

export const FormLogin = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { isLoaded, setActive, signIn } = useSignIn();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const handlerSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hasSubmitted) setHasSubmitted(true);

    if (emailAddress.length < 1) {
      setErrorMessage(t("PageLogin.enterEmail"));
    } else if (password.length < 1) {
      setErrorMessage(t("PageLogin.enterPassword"));
    } else {
      setErrorMessage("");
    }

    if (isSubmitting) return;
    if (!isLoaded) return;

    setIsSubmitting(true);

    try {
      await signIn.create({
        identifier: emailAddress,
        password,
      });

      setActive({
        session: signIn.createdSessionId,
      });

      dispatch(addToastMain(t("PageLogin.successToast")));
    } catch (error) {
      setErrorMessage(t("PageLogin.error"));
      dispatch(addToastMain(t("PageLogin.errorToast")));
    }

    setIsSubmitting(false);
  };

  const onClose = () => {
    setIsSubmitting(false);
    setHasSubmitted(false);
    setErrorMessage("");
    setEmailAddress("");
    setPassword("");
    setVisible(false);
  };

  const handleForgotPassword = () => {
    // Redirect to Clerk's forgot password URL
    const forgotPasswordUrl = `${signIn.signInUrl}/recover`;
    window.location.href = forgotPasswordUrl;
  };

  return (
    <>
      <CButton color="primary" onClick={() => setVisible(!visible)}>
        {t("PageLogin.login")}
      </CButton>
      <CModal visible={visible} onClose={onClose} aria-labelledby="login-form">
        <CForm onSubmit={handlerSubmit}>
          <CModalHeader>
            <CModalTitle>{t("PageLogin.modalTitle")}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <div className="flex flex-col gap-2">
              <CFormInput
                floatingLabel={t("PageLogin.email")}
                type="email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
              <CFormInput
                floatingLabel={t("PageLogin.password")}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mt-3 flex justify-between items-center text-sm">
              <button
                type="button"
                className="text-blue-500 hover:underline"
                onClick={handleForgotPassword}
              >
                {t("PageLogin.lostPassword")}
              </button>
            </div>
          </CModalBody>
          <CModalFooter className="flex flex-col gap-1 md:flex-row-reverse">
            <CButton
              color="primary"
              className="w-full md:flex-1"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CSpinner size="sm" />
              ) : (
                <span>{t("PageLogin.login")}</span>
              )}
            </CButton>
            <CButton
              color="secondary"
              className="w-full md:flex-1"
              onClick={onClose}
            >
              {t("PageLogin.closeButton")}
            </CButton>
          </CModalFooter>
          <div className="mt-3 text-center text-sm">
            <span>{t("PageLogin.noAccount")} </span>
            <button
              type="button"
              className="text-blue-500 hover:underline"
              onClick={() => navigate(`/register`)}
            >
              {t("PageLogin.register")}
            </button>
          </div>
        </CForm>
      </CModal>
    </>
  );
};
