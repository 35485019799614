import { UserButton } from "@clerk/clerk-react";
import { UserButtonContactInfo } from "./user-button-contact-info";
import { IconWrapper } from "./icon-wrapper";
import { BsPeople } from "react-icons/bs";

export const CustomUserButton = () => {
  return (
    <UserButton afterSignOutUrl="/">
      <UserButton.UserProfilePage
        label="Contact info"
        labelIcon={
          <IconWrapper>
            <BsPeople />
          </IconWrapper>
        }
        url="contact-info"
      >
        <UserButtonContactInfo />
      </UserButton.UserProfilePage>
    </UserButton>
  );
};
