import { useQuery } from "@tanstack/react-query";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import {
  CButton,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cifLt, cifPl, cifLv, cilLockLocked } from "@coreui/icons";
import { authedFetch } from "@/utils/authed-fetch";
import {
  EListingCategory,
} from "@/enums/listing-enums";
import { ECountry } from "@/enums/location-enums";
import { CategoryBadge } from "@/components/badges/category-badge";
import bannerImage from "src/assets/images/banner.png";
import buyersBenefitBannerImage from "src/assets/images/for_buyers.png";
import sellersBenefitBannerImage from "src/assets/images/for_sellers.png";

type TQuery = {
  count: number;
  listings: {
    id: number;
    category: EListingCategory;
    title: string;
    country: ECountry;
    deadline: string;
    created_at: string;
  }[];
};

export const AnonymousPageDiscover = () => {
  const { t } = useAppTranslation();

  const { data, isLoading } = useQuery<TQuery>({
    queryKey: ["new-listings"],
    queryFn: async () => {
      const response = await authedFetch({
        endpoint: "api/public/discover",
      });
      return response.json();
    },
  });

  const listings = data?.listings || [];
  const freeItemsCount = 3;

  const mapCountryIcon = (country: string) => {
    switch (country) {
      case "LT":
        return cifLt;
      case "PL":
        return cifPl;
      case "LV":
        return cifLv;
      default:
        return [];
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">

      <CCard className="mb-6 text-center text-white"
        style={{
          backgroundImage: `url(${bannerImage}), linear-gradient(to right, rgba(187, 222, 251, 0.8), rgba(144, 202, 249, 0.8))`,
          backgroundBlendMode: 'multiply',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.8, // Adjust transparency here
        }}>

        <CCardBody>
          <div className="bg-gray-900 bg-opacity-60 text-white p-4 rounded-lg shadow-md inline-block">
            <CCardTitle className="text-4xl font-bold">
              {t("PageDiscover.AnonymousPageDiscover.promotionTitle") || "Discover the Best Listings"}
            </CCardTitle>
            <CCardText className="mt-3 text-lg">
              {t("PageDiscover.AnonymousPageDiscover.promotionSubtitle") ||
                "Connect with buyers and sellers in the industrial machine marketplace. Start now!"}
            </CCardText>
            <CButton
              color="light"
              className="mt-4 px-4 py-2 text-lg font-bold rounded-lg border border-blue-400 text-blue-700 hover:bg-blue-100 transition-shadow shadow-md relative"
              onClick={() => (window.location.href = "/register")}
            >
              {/* Pulsing Dot */}
              <span className="absolute -top-1 -right-1 flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-400"></span>
              </span>
              {/* Button Text */}
              {t("PageDiscover.AnonymousPageDiscover.getStartedButton") || "Get Started"}
            </CButton>
          </div>
        </CCardBody>
      </CCard>

      <CContainer fluid className="relative flex flex-col items-center my-12 px-4">
        {/* Process Title */}
        
<div className="absolute -top-6 text-gray-800 text-4xl font-bold px-10 py-2 rounded-2xl">
    <>
    {t("PageDiscover.AnonymousPageDiscover.processTitlep1")} <span className="text-blue-500">{t("PageDiscover.AnonymousPageDiscover.processTitlep2")}</span>?
    </>
</div>
        <div className="pt-5 pb-5 rounded-t-3xl p-4 w-full"   style={{
    boxShadow: "0 4px 6px -2px rgba(0, 0, 0, 0.1)", // Custom shadow for bottom only
  }}>
          {/* Benefits for Buyers */}
          <CRow className="items-center mb-8">
            {/* Image Section */}
            <CCol sm={12} md={6} className="relative overflow-hidden">
              <div
                className="w-full h-64 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${buyersBenefitBannerImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                  clipPath: "polygon(0 0, 100% 0, 70% 100%, 0% 100%)", // Smooth edge transition
                }}
              ></div>
            </CCol>
            {/* Text Section */}
            <CCol sm={12} md={6} className="text-center md:text-left px-6">
              <h2 className="text-3xl font-semibold text-sky-600">
                {t("PageDiscover.AnonymousPageDiscover.benefitsForBuyers") || "Benefits for Buyers"}
              </h2>
              <ul className="text-lg mt-4 space-y-2 list-disc list-inside text-justify">
                <li>{t("PageDiscover.AnonymousPageDiscover.benefit1") || "Benefit 1 for Buyers"}</li>
                <li>{t("PageDiscover.AnonymousPageDiscover.benefit2") || "Benefit 2 for Buyers"}</li>
                <li>{t("PageDiscover.AnonymousPageDiscover.benefit3") || "Benefit 3 for Buyers"}</li>
              </ul>
            </CCol>
          </CRow>

          {/* Benefits for Sellers */}
          <CRow className="items-center">
            {/* Text Section */}
            <CCol sm={12} md={6} className="text-center md:text-left px-6">
              <h2 className="text-3xl font-semibold text-sky-600">
                {t("PageDiscover.AnonymousPageDiscover.benefitsForSellers") || "Benefits for Sellers"}
              </h2>
              <ul className="text-lg mt-4 space-y-2 list-disc list-inside text-justify">
                <li>{t("PageDiscover.AnonymousPageDiscover.benefit1Seller") || "Benefit 1 for Sellers"}</li>
                <li>{t("PageDiscover.AnonymousPageDiscover.benefit2Seller") || "Benefit 2 for Sellers"}</li>
                <li>{t("PageDiscover.AnonymousPageDiscover.benefit3Seller") || "Benefit 3 for Sellers"}</li>
              </ul>
            </CCol>
            {/* Image Section */}
            <CCol sm={12} md={6} className="relative overflow-hidden">
              <div
                className="w-full h-64 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${sellersBenefitBannerImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                  clipPath: "polygon(30% 0, 100% 0, 100% 100%, 0 100%)", // Smooth edge transition
                }}
              ></div>
            </CCol>
          </CRow>
        </div>
      </CContainer>

      {/* <CRow className="mb-6">
        <CCol md={4}>
          <CCard>
            <CCardBody>
              <CCardTitle>
                {t("PageDiscover.benefit1Title") || "Wide Variety of Listings"}
              </CCardTitle>
              <CCardText>
                {t("PageDiscover.benefit1Description") || "Access thousands of listings for industrial machines."}
              </CCardText>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={4}>
          <CCard>
            <CCardBody>
              <CCardTitle>{t("PageDiscover.benefit2Title") || "Easy to Use"}</CCardTitle>
              <CCardText>
                {t("PageDiscover.benefit2Description") || "Post listings or connect with sellers easily."}
              </CCardText>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={4}>
          <CCard>
            <CCardBody>
              <CCardTitle>{t("PageDiscover.benefit3Title") || "Secure Transactions"}</CCardTitle>
              <CCardText>
                {t("PageDiscover.benefit3Description") || "We prioritize your security and privacy."}
              </CCardText>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow> */}

      <h3 className="text-2xl font-bold mb-4">{t("PageDiscover.AnonymousPageDiscover.searchListings")}</h3>

      {/* Table */}
      <div className="relative overflow-hidden border rounded-lg shadow-md">
        <CTable className="bg-white">
          <CTableBody>
            <CTableRow className="bg-gray-100">
              <CTableHeaderCell>#</CTableHeaderCell>
              <CTableHeaderCell>{t("PageDiscover.SearchListingsTable.title")}</CTableHeaderCell>
              <CTableHeaderCell>{t("PageDiscover.SearchListingsTable.country")}</CTableHeaderCell>
              <CTableHeaderCell>{t("PageDiscover.SearchListingsTable.category")}</CTableHeaderCell>
            </CTableRow>
            {isLoading
              ? Array.from({ length: freeItemsCount }).map((_, index) => (
                <CTableRow key={index}>
                  <CTableDataCell colSpan={4} className="text-center py-4">
                    {t("AnonymousPageDiscover.loading")}
                  </CTableDataCell>
                </CTableRow>
              ))
              : listings.map((item) => (
                <CTableRow key={item.id}>
                  <CTableDataCell>{item.id}</CTableDataCell>
                  <CTableDataCell>{item.title}</CTableDataCell>
                  <CTableDataCell>
                    <div className="flex items-center">
                      <CIcon icon={mapCountryIcon(item.country)} size="lg" className="mr-2" />
                      {item.country}
                    </div>
                  </CTableDataCell>
                  <CTableDataCell><CategoryBadge category={item?.category} /></CTableDataCell>
                </CTableRow>
              ))}

            {/* Banner Row */}
            <CTableRow>
              <CTableDataCell colSpan={4} className="bg-blue-50 text-center py-6">
                <div className="flex flex-col items-center gap-4">
                  {/* Lock Icon in Header */}
                  <div className="flex items-center gap-2">
                    <CIcon icon={cilLockLocked} size="lg" className="text-blue-700" />
                    <span className="text-blue-700 text-lg font-medium">
                      {t("PageDiscover.AnonymousPageDiscover.cta")}
                    </span>
                  </div>
                  <button
                    onClick={() => (window.location.href = "/login")}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition animate-pulse"
                  >
                    {t("PageLogin.loginOrRegister")}
                  </button>
                </div>
              </CTableDataCell>
            </CTableRow>


            {/* Placeholder Rows Under the Banner */}
            {Array.from({ length: 5 }).map((_, index) => (
              <CTableRow key={`placeholder-${index}`} className="bg-gray-200 opacity-50 blur-sm">
                <CTableDataCell className="py-4">---</CTableDataCell>
                <CTableDataCell className="py-4">
                  {(() => {
                    const texts = [
                      "Locked Content Locked Content Locked Content Locked Content, Locked Content",
                      "1555: Locked Content Locked Content Locked Content",
                      "12: Locked Content Locked Content!",
                    ];
                    const randomText = texts[Math.floor(Math.random() * texts.length)];
                    return randomText;
                  })()}
                </CTableDataCell>
                <CTableDataCell className="py-4">
                  <div className="flex items-center">
                    {(() => {
                      const countries = ["PL", "LT", "LV"];
                      const randomCountry = countries[Math.floor(Math.random() * countries.length)];
                      return (
                        <>
                          <CIcon icon={mapCountryIcon(randomCountry)} size="lg" className="mr-2" />
                          {randomCountry}
                        </>
                      );
                    })()}
                  </div>
                </CTableDataCell>

                <CTableDataCell className="py-4"><CategoryBadge category={EListingCategory.WOOD_SCANNERS} /></CTableDataCell>
              </CTableRow>
            ))}

            {/* Fake Pagination */}
            <div className="pb-1 absolute bottom-0 left-3 right-0 flex items-center justify-center gap-4 bg-gray-100 opacity-100 blur-sm py-4">
              <button className="px-3 py-1 rounded bg-gray-300">1</button>
              <button className="px-3 py-1 rounded bg-gray-300">2</button>
              <button className="px-3 py-1 rounded bg-gray-300">3</button>
              <span>...</span>
            </div>
          </CTableBody>
        </CTable>
      </div>
    </div>
  );
};

export default AnonymousPageDiscover;
