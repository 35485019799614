import CIcon from "@coreui/icons-react";
import {
  cilBullhorn,
  cilDescription,
  cilClipboard,
  cilStar,
  cilMagnifyingGlass,
  cilBook,
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react";

type TNavItem = {
  component: typeof CNavItem | typeof CNavTitle;
  name: string;
  to?: string;
  icon?: React.ReactElement;
  isListItem?: boolean;
  badge?: {
    color: string;
    text: string;
    className?: string;
  };
};

const _nav_bottom: TNavItem[] = [
  {
    component: CNavTitle,
    name: "UI.Navigation.important",
  },
  {
    component: CNavItem,
    name: "UI.Navigation.termsAndConditions",
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    to: "/terms-and-conditions",
  }
];

export const _navAnonymous: TNavItem[] = [
  {
    component: CNavItem,
    name: "UI.Navigation.about",
    icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
    to: "/about",
  },
  {
    component: CNavItem,
    name: "UI.Navigation.register",
    to: "/register",
    icon: <CIcon icon={cilMagnifyingGlass} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "UI.Navigation.termsAndConditions",
    to: "/terms-and-conditions",
    icon: <CIcon icon={cilMagnifyingGlass} customClassName="nav-icon" />,
  }
];

export const _navOfficeUser: TNavItem[] = [
  {
    component: CNavItem,
    name: "UI.Navigation.howToUse",
    icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
    to: "/user-manual",
  },
  {
    component: CNavItem,
    name: "UI.Navigation.discover",
    to: "/discover",
    icon: <CIcon icon={cilMagnifyingGlass} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "UI.Navigation.yourProfile",
  },
  {
    component: CNavItem,
    name: "UI.Navigation.listings",
    icon: <CIcon icon={cilBullhorn} customClassName="nav-icon" />,
    to: "/listing",
  },
  {
    component: CNavItem,
    name: "UI.Navigation.myDrafts",
    isListItem: true,
    icon: (
      <>
        <span className="nav-icon">
          <span className="nav-icon-bullet"></span>
        </span>
      </>
    ),
    to: "/my-drafts",
  },
  {
    component: CNavItem,
    name: "UI.Navigation.myEnrollments",
    to: "/enrollments",
    icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
  },
];

export const _navEquipmentProvider: TNavItem[] = [
  {
    component: CNavItem,
    name: "UI.Navigation.howToUse",
    icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
    to: "/user-manual"
  },
  {
    component: CNavItem,
    name: "UI.Navigation.discover",
    to: "/discover",
    icon: <CIcon icon={cilMagnifyingGlass} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "UI.Navigation.yourProfile",
  },
  {
    component: CNavItem,
    name: "UI.Navigation.myEnrollments",
    to: "/enrollments",
    icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "UI.Navigation.myFavorites",
    to: "/my-favorites",
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  },
];

export const _navHorizontalOfficeUser: TNavItem[] = [
  {
    component: CNavItem,
    name: "UI.Navigation.discover",
    to: "/discover",
    icon: <CIcon icon={cilMagnifyingGlass} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "UI.Navigation.listings",
    icon: <CIcon icon={cilBullhorn} customClassName="nav-icon" />,
    to: "/listing",
  },
  {
    component: CNavItem,
    name: "UI.Navigation.myDrafts",
    isListItem: true,
    icon: (
      <>
        <span className="nav-icon">
          <span className="nav-icon-bullet"></span>
        </span>
      </>
    ),
    to: "/my-drafts",
  },
  {
    component: CNavItem,
    name: "UI.Navigation.myEnrollments",
    to: "/enrollments",
    icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
  },
];

export default _nav_bottom;
