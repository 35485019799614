import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cifLt, cifPl, cifLv } from "@coreui/icons";
import { authedFetch } from "@/utils/authed-fetch";
import { CategoryBadge } from "@/components/badges/category-badge";
import { EListingCategory } from "@/enums/listing-enums";
import { ECountry } from "@/enums/location-enums";

type TQuery = {
  count: number;
  listings: {
    id: number;
    category: EListingCategory;
    title: string;
    country: ECountry;
    deadline: string;
    created_at: string;
  }[];
};

const AnonymousPageDiscover: React.FC = () => {
  const { t } = useAppTranslation();

  const { data, isLoading } = useQuery<TQuery>({
    queryKey: ["new-listings"],
    queryFn: async () => {
      const response = await authedFetch({
        endpoint: "api/public/discover",
      });
      return response.json();
    },
  });

  const listings = data?.listings || [];
  const freeItemsCount = 3;

  const mapCountryIcon = (country: string) => {
    switch (country) {
      case "LT":
        return cifLt;
      case "PL":
        return cifPl;
      case "LV":
        return cifLv;
      default:
        return [];
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">

      {/* Listings Section */}
      <h3 className="text-2xl font-bold mb-4">
        {t("PageDiscover.searchListings")}
      </h3>
      <div className="relative overflow-hidden border rounded-lg shadow-md">
        <CTable className="bg-white">
          <CTableBody>
            <CTableRow className="bg-gray-100">
              <CTableHeaderCell>#</CTableHeaderCell>
              <CTableHeaderCell>
                {t("PageDiscover.SearchListingsTable.title")}
              </CTableHeaderCell>
              <CTableHeaderCell>
                {t("PageDiscover.SearchListingsTable.country")}
              </CTableHeaderCell>
              <CTableHeaderCell>
                {t("PageDiscover.SearchListingsTable.category")}
              </CTableHeaderCell>
            </CTableRow>
            {isLoading
              ? Array.from({ length: freeItemsCount }).map((_, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell colSpan={4} className="text-center py-4">
                      {t("AnonymousPageDiscover.loading")}
                    </CTableDataCell>
                  </CTableRow>
                ))
              : listings.map((item) => (
                  <CTableRow key={item.id}>
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell>{item.title}</CTableDataCell>
                    <CTableDataCell>
                      <div className="flex items-center">
                        <CIcon
                          icon={mapCountryIcon(item.country)}
                          size="lg"
                          className="mr-2"
                        />
                        {item.country}
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>
                      <CategoryBadge category={item?.category} />
                    </CTableDataCell>
                  </CTableRow>
                ))}
          </CTableBody>
        </CTable>
      </div>
    </div>
  );
};

export default AnonymousPageDiscover;
