import { AnonymousHorizontalNavItems } from "./anonymous-horizontal-nav-items";

// @ts-ignore
import logo from "src/assets/images/logo.svg";
// @ts-ignore
import { sygnet } from "src/assets/brand/sygnet";

// sidebar nav config
import { _navAnonymous } from "../../_nav";
import _nav from "../../_nav";
import { CButton, CContainer, CNavbar, CNavbarBrand, CTooltip } from "@coreui/react";
import { cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { t } from "i18next";
import { useState } from "react";
import LoginRegisterModal from "./login-register-modal";


const AnonymousHorizontalNav = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navItems =  [..._navAnonymous];

  return (
    <CNavbar expand="lg" className="rounded-b-lg" style={{ backgroundColor: "#f7f7f7" }}>
      <CContainer>
        <CNavbarBrand href="/"></CNavbarBrand>
          <AnonymousHorizontalNavItems items={navItems} />
          <CTooltip content={t("UI.Buttons.newListing__tooltip")}>
            <CButton
              color="info"
              onClick={() => setIsModalOpen(true)}
            >
              <div className="flex items-center justify-center w-full gap-1 animate-pulse">
                <CIcon icon={cilPlus} />
                <span className="text-lg font-bold">
                  {t("UI.Buttons.newListing")}</span>
              </div>
            </CButton>
          </CTooltip>
          <LoginRegisterModal
            visible={isModalOpen}
           onClose={() => setIsModalOpen(false)}
         />

      </CContainer>
    </CNavbar>
  );
};

export default AnonymousHorizontalNav;
