import { useEffect, useRef } from "react";
import { CContainer, CHeader, CHeaderNav, CHeaderToggler } from "@coreui/react";
import logo from "src/assets/images/logo.svg";

// import { AppHeaderDropdown } from "../header";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setSidebarShow } from "../../store/ui/slice";
import { LocaleSelect } from "../locale/locale-select";
import { AppHeaderDropdown } from "..";

const AnonymousHorizontalHeader = () => {
  const headerRef = useRef<any>();

  const dispatch = useAppDispatch();
  const sidebarShow = useAppSelector((state) => state.ui.sidebarShow);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      headerRef.current &&
        headerRef.current.classList.toggle(
          "shadow-sm",
          document.documentElement.scrollTop > 0
        );
    });
  }, []);

  return (
    <CHeader position="sticky" className="p-2 " style={{ background: "linear-gradient(to left, #ebf5ff, #d1e9ff)" }} ref={headerRef}>
      <CContainer className="pt-4 pb-4">
        <CHeaderToggler
          onClick={() => dispatch(setSidebarShow(!sidebarShow))}
          style={{ marginInlineStart: "-14px" }}
        >
        </CHeaderToggler>
        <a href="/">
         <img src={logo} alt="Logo" width={300} />
        </a>
        <CHeaderNav>
          <LocaleSelect />
          <li className="py-1 nav-item">
            <div className="mx-2 text-opacity-75 vr h-100 text-body"></div>
          </li>
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default AnonymousHorizontalHeader;
