import {
  CCloseButton,
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarHeader,
  CSidebarToggler,
} from "@coreui/react";

import { AnonymousAppSidebarNav } from "./anonymous-sidebar-nav";

// @ts-ignore
import logo from "src/assets/images/logo.svg";
// @ts-ignore
import { sygnet } from "src/assets/brand/sygnet";

// sidebar nav config
import { _navAnonymous, _navEquipmentProvider, _navOfficeUser } from "../../_nav";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setSidebarShow, setSidebarUnfoldable } from "../../store/ui/slice";
import _nav from "../../_nav";


const AnonymousAppSidebar = () => {
  const dispatch = useAppDispatch();
  const unfoldable = useAppSelector((state) => state.ui.sidebarUnfoldable);
  const sidebarShow = useAppSelector((state) => state.ui.sidebarShow);

  const navItems =  [..._navAnonymous, ..._nav];

  return (
      <CSidebar
        className="border-end"
        // colorScheme="dark"
        position="fixed"
        unfoldable={unfoldable}
        visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch(setSidebarShow(visible));
        }}
      >
        <CSidebarHeader className="border-bottom">
          {/* @ts-ignore */}
          <CSidebarBrand to="/">
            <img src={logo} alt="Logo" width={300} />
          </CSidebarBrand>
          <CCloseButton
            className="d-lg-none"
            dark
            onClick={() => dispatch(setSidebarShow(false))}
          />
        </CSidebarHeader>
        {/* @ts-ignore */}
        <AnonymousAppSidebarNav items={navItems} />
        <CSidebarFooter className="border-top d-none d-lg-flex">
          <CSidebarToggler
            onClick={() => dispatch(setSidebarUnfoldable(!unfoldable))}
          />
        </CSidebarFooter>
      </CSidebar>
  );
};

export default AnonymousAppSidebar;
