import React from 'react';
import { CFooter } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilEnvelopeClosed } from '@coreui/icons';

import { useAppTranslation } from "@/locales/helpers/translation-helpers";

const AppFooter = () => {
  const { t } = useAppTranslation();
  return (
    <CFooter className="px-4 py-4" style={{ backgroundColor: '#f8f9fa', color: '#6c757d', padding: '20px 0' }}>
        <ul className="list-unstyled">
        <h6 style={{ color: '#6c757d' }}>{t("UI.Footer.contact")}</h6>
          <li>
            <a href="mailto:support@machinevertical.com" style={{ color: '#6c757d', display: 'flex', alignItems: 'center' }}>
              <CIcon icon={cilEnvelopeClosed} className="me-1" />
              support@machinevertical.com
            </a>
          </li>
        </ul>
      <div className="d-flex align-items-center mb-3">
        <span>&copy; 2025 MachineVertical. {t("UI.Footer.copyright")}</span>
      </div>
    </CFooter>
  );
};

export default React.memo(AppFooter);
