import React from 'react';
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilUser, cilArrowRight, cilXCircle } from '@coreui/icons';
import { useAppTranslation } from '@/locales/helpers/translation-helpers';
import { useNavigate } from 'react-router-dom';

interface LoginRegisterModalProps {
  visible: boolean;
  onClose: () => void;
}

const LoginRegisterModal: React.FC<LoginRegisterModalProps> = ({ visible, onClose }) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  return (
    <CModal alignment="center" visible={visible} onClose={onClose} className="modern-modal">
      {/* Modal Header */}
      <CModalHeader closeButton className="text-center">
        <div className="w-full text-center">
          <CIcon icon={cilUser} size="3xl" className="text-blue-500 mb-2" />
          <h2 className="text-2xl font-semibold text-gray-800">
            {t('Modals.RegisterLoginModal.loginRegister')}
          </h2>
        </div>
      </CModalHeader>

      {/* Modal Body */}
      <CModalBody className="text-center">
        <p className="text-gray-600 text-lg mb-4">
          {t('Modals.RegisterLoginModal.loginOrRegisterText')}
        </p>
      </CModalBody>

      {/* Modal Footer */}
      <CModalFooter className="flex flex-col items-center gap-6">
        {/* Login Section */}
        <div className="flex flex-col items-center w-full gap-2">
          <p className="text-gray-500 text-sm">{t('Modals.RegisterLoginModal.haveAnAccount')}</p>
          <CButton
            color="primary"
            className="flex items-center gap-2 w-full"
            onClick={() => (window.location.href = "/login")}
          >
            <CIcon icon={cilArrowRight} />
            <span>{t('Modals.RegisterLoginModal.login')}</span>
          </CButton>
        </div>

        {/* Register Section */}
        <div className="flex flex-col items-center w-full gap-2">
          <p className="text-gray-500 text-sm">{t('Modals.RegisterLoginModal.newHere')}</p>
          <CButton
            color="success"
            className="flex items-center gap-2 w-full"
            onClick={() => (window.location.href = "/register")}
          >
            <CIcon icon={cilArrowRight} />
            <span>{t('Modals.RegisterLoginModal.register')}</span>
          </CButton>
        </div>

        {/* Close Button */}
        <CButton
          color="secondary"
          className="flex items-center gap-2 w-full"
          onClick={onClose}
        >
          <CIcon icon={cilXCircle} />
          <span>{t('Modals.RegisterLoginModal.close')}</span>
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default LoginRegisterModal;
