import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import {
  CBadge,
  CButton,
  CNavLink,
  CSidebarNav,
  CTooltip,
} from "@coreui/react";
import {
  useAppTranslation,
  ValidI18nKey,
} from "@/locales/helpers/translation-helpers";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";

type TBadge = {
  color: string;
  text: string;
};

type TItem = {
  items?: TItem[];
  item?: TItem;
  component: any;
  to?: string;
  href?: string;
  badge?: TBadge;
  name: ValidI18nKey;
  icon: React.ReactNode;
  isListItem?: boolean;
};

export const AnonymousAppSidebarNav = ({ items }: { items: TItem[] }) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const navLink = (
    name: ValidI18nKey,
    icon: React.ReactNode,
    badge?: TBadge,
    indent = false
  ) => {
    return (
      <>
        {icon
          ? icon
          : indent && (
            <span className="nav-icon">
              <span className="nav-icon-bullet"></span>
            </span>
          )}
        {!!name ? t(name) : null}
        {badge && (
          <CBadge color={badge.color} className="ms-auto animate-pulse">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };

  const navItem = (item: TItem, index: number, indent = false) => {
    const { component, name, badge, icon, isListItem, ...rest } = item;
    const Component = component;
    return (
      <Component as="div" key={index}>
        {rest.to || rest.href ? (
          <CNavLink {...(rest.to && { as: NavLink })} {...rest}>
            {navLink(name, icon, badge, indent)}
          </CNavLink>
        ) : (
          navLink(name, icon, badge, indent)
        )}
      </Component>
    );
  };

  const navGroup = (item: TItem, index: number) => {
    const { component, name, icon, items, to, ...rest } = item;
    const Component = component;
    return (
      <Component
        compact
        as="div"
        key={index}
        toggler={navLink(name, icon)}
        {...rest}
      >
        {item.items?.map((item: TItem, index: number) =>
          item.items ? navGroup(item, index) : navItem(item, index, true)
        )}
      </Component>
    );
  };

  return (
    <>
      <CSidebarNav as={SimpleBar}>
        <div className="flex flex-col px-2 pt-2 pb-2">
          <CTooltip content={t("UI.Buttons.newListing__tooltip")}>
            <CButton
              color="primary"
              onClick={() => {
                navigate(`/listing/create`);
              }}
            >
              <div className="flex items-center justify-center w-full gap-1">
                <CIcon icon={cilPlus} />
                <span>{t("UI.Buttons.newListing")}</span>
              </div>
            </CButton>
          </CTooltip>
        </div>

        {items &&
          items.map((item, index) =>
            item.items ? navGroup(item, index) : navItem(item, index)
          )}
      </CSidebarNav>
    </>
  );
};

AnonymousAppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
