import { EUserContactInfoType } from "@/enums/user-metadata";
import { useUser } from "@clerk/clerk-react";
import { CButton, CForm, CFormTextarea } from "@coreui/react";
import { useEffect, useState } from "react";

type TFormData = {
  phoneNumbers?: {
    type: EUserContactInfoType;
    phoneNumber: string;
    isLive?: boolean;
    toDelete?: boolean;
  }[];
  notes?: string;
};

type TContactInfo = {
  phoneNumbers?: {
    type: EUserContactInfoType;
    phoneNumber: string;
  }[];
  notes?: string;
};

const NOTES_MAX_LEN = 250;

export const UserButtonContactInfo = () => {
  const { user, isLoaded, isSignedIn } = useUser();
  const contactInfo = user?.unsafeMetadata?.contactInfo as TContactInfo;

  const defaultFormData: TFormData = {};

  const [formData, setFormData] = useState(defaultFormData);

  const isDisabled = (formData?.notes ?? "") === (contactInfo?.notes ?? "");

  const handleChangeNotes = (newNotes: string) => {
    setFormData((state) => ({
      ...state,
      notes: newNotes,
    }));
  };

  // const handlerTestEndpoint = async () => {
  //   const url = "localhost:3000/api/health";
  //   const data = await fetch(url).then((res) => res.json());
  //   console.log({ data });
  // };

  useEffect(() => {
    if (isLoaded && isSignedIn && contactInfo?.notes) {
      //
    }
  }, [isLoaded, isSignedIn]);

  return (
    <div>
      <h1 className="text-[17px] font-bold mb-3">Contact Info</h1>
      <div className="border-t-[1px] border-solid">
        <CForm>
          <div className="pt-3">
            {/* <p>Some custom page</p> */}
            {/* <CInputGroup>
            <CInputGroupText className="w-60">
            <CFormSelect
            size="sm"
            options={countryTelCodes.map((item) => ({
              value: item.dial_code,
              label: `${item.name}(${item.dial_code})`,
            }))}
            />
            </CInputGroupText>
            <CFormInput type="tel" placeholder="Phone number" />
            <CInputGroupText>
            <CFormCheck label="Primary" />
            </CInputGroupText>
          </CInputGroup> */}
            <CFormTextarea
              id="exampleFormControlTextarea1"
              label="Notes"
              rows={3}
              text={`Max length ${NOTES_MAX_LEN}. (${formData?.notes?.length ?? 0}/${NOTES_MAX_LEN})`}
              value={formData?.notes ?? ""}
              onChange={(e) => {
                if (e.target.value.length <= NOTES_MAX_LEN)
                  handleChangeNotes(e.target.value);
              }}
            ></CFormTextarea>
          </div>

          <div className="flex justify-end w-full py-3">
            <CButton
              color="primary"
              disabled={isDisabled}
              // onClick={handlerTestEndpoint}
            >
              Save changes
            </CButton>
          </div>
        </CForm>
      </div>
    </div>
  );
};
