import React from "react";
import { Link, useParams } from "react-router-dom";

import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import {
  TranslateFunction,
  useAppTranslation,
} from "@/locales/helpers/translation-helpers";

type TT = TranslateFunction;

const BreadcrumbTemplates = {
  // COMMON ROUTES
  HOME: (t: TT) => ({
    label: "",
    link: "/",
  }),
  TERMS_AND_CONDITIONS: (t: TT) => ({
    label: t("UI.Navigation.termsAndConditions"),
    link: "/terms-and-conditions",
  }),
  ABOUT_US: (t: TT) => ({
    label: t("UI.Navigation.about"),
    link: "/about",
  }),
  HOW_TO_USE: (t: TT) => ({
    label: t("UI.Navigation.howToUse"),
    link: "/user-manual",
  }),

  // OFFICE ROUTES
  DASHBOARD: (t: TT) => ({
    label: t("UI.Navigation.dashboard"),
    link: "/dashboard",
  }),
  LISTINGS: (t: TT) => ({
    label: t("UI.Navigation.listings"),
    link: "/listing",
  }),
  MY_DRAFTS: (t: TT) => ({
    label: t("UI.Navigation.myDrafts"),
    link: "/my-drafts",
  }),
  LISTING_GROUPS: (t: TT) => ({
    label: t("UI.Navigation.listingGroups"),
    link: "/listing-group",
  }),
  LISTING_GROUP__CREATE: (t: TT) => ({
    label: t("UI.Navigation.listingGroupCreate"),
    link: "/listing-groups/create",
  }),
  LISTING_GROUP__VIEW: (t: TT, groupId?: string | number) => ({
    label: t("UI.Navigation.listingGroup", { groupId }),
    link: `/listing-group/${groupId}`,
  }),
  LISTING__CREATE: (t: TT, groupId?: string | number) => ({
    label: t("UI.Navigation.listingCreate"),
    link: `/listing-group/${groupId}/listing/create`,
  }),
  LISTING__VIEW: (
    t: TT,
    groupId?: string | number,
    listingId?: string | number
  ) => ({
    label: t("UI.Navigation.listingView", { listingId }),
    link: `/listing-group/${groupId}/listing/${listingId}`,
  }),

  // EQUIPMENT PROVIDER ROUTES
  DISCOVER: (t: TT) => ({
    label: t("UI.Navigation.discover"),
    link: "/discover",
  }),
  DISCOVER_SHOW: (t: TT, listingId?: string | number) => ({
    label: t("UI.Navigation.discoverListing", { listingId }),
    link: `/discover/${listingId}`,
  }),
  OFFER_CREATE: (
    t: TT,
    listingId?: string | number,
    enrollmentId?: string | number
  ) => ({
    label: t("UI.Navigation.offerCreate"),
    link: `/discover/${listingId}/offer/${enrollmentId}/create`,
  }),
  OFFER_VIEW: (
    t: TT,
    listingId?: string | number,
    offerId?: string | number,
  ) => ({
    label: t("UI.Navigation.offerView", {offerId}),
    link: `/listing/${listingId}/offer/${offerId}/view`,
  }),
  OFFER_EDIT: (
    t: TT,
    listingId?: string | number,
    enrollmentId?: string | number,
    offerId?: string | number
  ) => ({
    label: t("UI.Navigation.offerEdit", { offerId }),
    link: `/discover/${listingId}/offer/${enrollmentId}/${offerId}/edit`,
  }),
  MY_ENROLLMENTS: (t: TT) => ({
    label: t("UI.Navigation.myEnrollments"),
    link: "/enrollments",
  }),
  MY_FAVORITES: (t: TT) => ({
    label: t("UI.Navigation.myFavorites"),
    link: "/my-favorites",
  }),
};

export type TBreadcrumbKeys = keyof typeof BreadcrumbTemplates;
type TBreadcrumbMap = {
  [key in TBreadcrumbKeys]: ({
    t,
    groupId,
    listingId,
  }: {
    t: TT;
    groupId?: string | number;
    listingId?: string | number;
    enrollmentId?: string | number;
    offerId?: string | number;
  }) => {
    label: string;
    link: string;
  }[];
};

export const BreadcrumbMap: TBreadcrumbMap = {
  // COMMON ROUTES
  HOME: ({ t }) => [BreadcrumbTemplates.HOME(t)],
  TERMS_AND_CONDITIONS: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.TERMS_AND_CONDITIONS(t),
  ],
  ABOUT_US: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.ABOUT_US(t),
  ],
  HOW_TO_USE: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.HOW_TO_USE(t),
  ],

  // OFFICE ROUTES
  DASHBOARD: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.DASHBOARD(t),
  ],
  LISTINGS: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.LISTINGS(t),
  ],
  MY_DRAFTS: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.MY_DRAFTS(t),
  ],
  LISTING_GROUPS: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.LISTING_GROUPS(t),
  ],
  LISTING_GROUP__CREATE: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.LISTING_GROUPS(t),
    BreadcrumbTemplates.LISTING_GROUP__CREATE(t),
  ],
  LISTING_GROUP__VIEW: ({ t, groupId }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.LISTING_GROUPS(t),
    BreadcrumbTemplates.LISTING_GROUP__VIEW(t, groupId),
  ],
  LISTING__CREATE: ({ t, groupId }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.LISTING_GROUPS(t),
    BreadcrumbTemplates.LISTING_GROUP__VIEW(t, groupId),
    BreadcrumbTemplates.LISTING__CREATE(t, groupId),
  ],
  LISTING__VIEW: ({ t, groupId, listingId }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.LISTING_GROUPS(t),
    BreadcrumbTemplates.LISTING_GROUP__VIEW(t, groupId),
    BreadcrumbTemplates.LISTINGS(t),
    BreadcrumbTemplates.LISTING__VIEW(t, groupId, listingId),
  ],

  // EQUIPMENT PROVIDER ROUTES
  DISCOVER: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.DISCOVER(t),
  ],
  DISCOVER_SHOW: ({ t, listingId }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.DISCOVER(t),
    BreadcrumbTemplates.DISCOVER_SHOW(t, listingId),
  ],
  OFFER_CREATE: ({ t, listingId, enrollmentId }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.DISCOVER(t),
    BreadcrumbTemplates.DISCOVER_SHOW(t, listingId),
    BreadcrumbTemplates.OFFER_CREATE(t, listingId, enrollmentId),
  ],
  OFFER_EDIT: ({ t, listingId, enrollmentId, offerId }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.DISCOVER(t),
    BreadcrumbTemplates.DISCOVER_SHOW(t, listingId),
    BreadcrumbTemplates.OFFER_EDIT(t, listingId, enrollmentId, offerId),
  ],
  OFFER_VIEW: ({ t, groupId, listingId, offerId }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.LISTING_GROUPS(t),
    BreadcrumbTemplates.LISTING_GROUP__VIEW(t, groupId),
    BreadcrumbTemplates.LISTINGS(t),
    BreadcrumbTemplates.LISTING__VIEW(t, groupId, listingId),
    BreadcrumbTemplates.OFFER_VIEW(t, listingId, offerId),
  ],
  MY_ENROLLMENTS: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.MY_ENROLLMENTS(t),
  ],
  MY_FAVORITES: ({ t }) => [
    BreadcrumbTemplates.HOME(t),
    BreadcrumbTemplates.MY_FAVORITES(t),
  ],
};

const AppBreadcrumb = () => {
  const { t } = useAppTranslation();
  const { groupId, listingId, enrollmentId, offerId } = useParams();
  const { breadcrumbSelected } = useBreadcrumbs();

  const breadcrumbItems = BreadcrumbMap[breadcrumbSelected]({
    t,
    groupId,
    listingId,
    enrollmentId,
    offerId,
  });

  return (
    <CBreadcrumb className="my-0">
      {breadcrumbItems.map((item, _index) => {
        if (_index === breadcrumbItems.length - 1) {
          return (
            <CBreadcrumbItem key={_index} active>
              {item.label}
            </CBreadcrumbItem>
          );
        }
        return (
          <CBreadcrumbItem key={_index}>
            <Link to={item.link}>{item.label}</Link>
          </CBreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
};

export default React.memo(AppBreadcrumb);
