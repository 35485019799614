import { SignedIn, SignedOut } from "@clerk/clerk-react";
// @ts-ignore
import avatar8 from "./../../assets/images/avatars/8.jpg";
import { FormLogin } from "../forms/form-login";
import { CustomUserButton } from "./user-button-pages/custom-user-button";

const AppHeaderDropdown = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <SignedIn>
        <CustomUserButton />
      </SignedIn>
      <SignedOut>
        <FormLogin />
      </SignedOut>
    </div>
  );
};

export default AppHeaderDropdown;
